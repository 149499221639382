/* eslint-disable react/prop-types */
import { differenceInDays, formatDistance, format } from 'date-fns';
import AuthorList from './author-list';
import BlogContainer from '../components/BlogContainer';
import BlogMain from '../components/BlogMain';
import BlogFeatureImage from '../components/BlogFeatureImage';
import BlogCard from '../components/BlogCard';
import Post from '../components/Post';
import { graphql } from 'gatsby';
import PortableText from './portableText';
import React from 'react';
import { buildImageObj } from '../libs/helpers';
import { imageUrlFor } from '../libs/image-url';

// erzeugt die einzelnen Post Hauptseiten
// Blog Layout hier

const BlogPost = (props) => {
  const {
    _rawBody,
    markdown,
    authors,
    categories,
    title,
    mainImage,
    publishedAt,
  } = props;

  return (
    <BlogContainer className="blog">
      <BlogFeatureImage>
        {mainImage && mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 26) * 1200))
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        )}
      </BlogFeatureImage>
      <BlogMain>
        <BlogCard>
          <h1 className="title">{title} </h1>
          <Post>
            <div className="blog-content">
              {' '}
              {_rawBody && <PortableText blocks={_rawBody} />}
            </div>
            {/*   <div className="markdown">{markdown}</div> */}
            <aside className="metaContent">
              {publishedAt && (
                <div className="publishedAt">
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? formatDistance(new Date(publishedAt), new Date())
                    : format(new Date(publishedAt), 'MMMM Mo, yyyy')}
                </div>
              )}
              {authors && <AuthorList items={authors} title="Authors" />}
              {categories && (
                <div className="categories">
                  <h3 className="categoriesHeadline">Categories</h3>
                  <ul>
                    {categories.map((category) => (
                      <li key={category._id}>{category.title} </li>
                    ))}
                  </ul>
                </div>
              )}
            </aside>
          </Post>
        </BlogCard>
      </BlogMain>
    </BlogContainer>
  );
};

export default BlogPost;
